import React, { useEffect, useRef } from "react"
import { ImageLink } from "components/image-link/ImageLink"
import { RepeatingImage } from "components/repeating-image/RepeatingImage"
import { graphql } from "gatsby"
import "./affiliation-slider.module.scss"
import { KenticoImageLink } from "components/image-link/image-link"
import { KenticoSingleImage } from "components/image/single-image"
import { useKeydownNavigation } from "hooks/keyboardEvents/useKeydownNavigation"

interface AffiliationProps {
  heading?: string
  subHeading?: string
  images?: KenticoImageLink[] | KenticoSingleImage[]
  backgroundIconUrl?: string
  isFromContentParser?: boolean
  position: number
  isAltBg?: boolean
}

export const AffiliationSlider = ({
  heading,
  subHeading,
  images,
  position,
  isAltBg,
}: AffiliationProps) => {
  const gridLabel = `grid-${position}_label`
  const gridId = `affiliation-slider-grid-${position}`
  const gridRowRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!gridRowRef.current) return

    const gridLinks = gridRowRef.current.querySelectorAll(
      "a[role=gridcell]"
    ) as NodeListOf<HTMLAnchorElement> | undefined

    if (!gridLinks) return

    const gridLinksList = Array.from(gridLinks)

    const tabsKeyboardEvent = useKeydownNavigation({ tabs: gridLinksList })

    gridLinks.forEach(link => {
      link.addEventListener("keydown", tabsKeyboardEvent.onKeydown)
    })
  }, [gridRowRef])

  return (
    <section
      className={`${
        isAltBg ? "greyBg " : ""
      }affiliation-slider u-content-block-box`}
    >
      <div className="fr-container">
        {(heading || subHeading) && (
          <div className="affiliation-slider__title">
            {heading && <h2 id={gridLabel}>{heading}</h2>}
            {subHeading && <h3>{subHeading}</h3>}
          </div>
        )}
        <div
          id={gridId}
          role="grid"
          aria-labelledby={gridLabel}
          data-wrap-cols="true"
          data-wrap-rows="true"
          data-restructure="true"
        >
          {images && images?.length > 0 && (
            <div
              role="row"
              className="affiliation-slider__grid "
              ref={gridRowRef}
            >
              {images.map(({ id, elements, system }, i) => {
                const isImageLink = system.type === "image_link"
                const isFirstLink = i === 0
                if (isImageLink) {
                  return (
                    <ImageLink
                      role="gridcell"
                      key={id}
                      imageLink={elements as KenticoImageLink["elements"]}
                      tabIndex={isFirstLink ? 0 : -1}
                    />
                  )
                } else {
                  return (
                    <RepeatingImage
                      role="gridcell"
                      key={id}
                      images={elements as KenticoSingleImage["elements"]}
                    />
                  )
                }
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment LogoSliderFragment on kontent_item_logo_slider {
    system {
      type
    }
    id
    elements {
      logo_slider_heading {
        value
      }
      sub_heading {
        value
      }
      logo_slider {
        value {
          ...SingleImageFragment
          ...ImageLinkFragment
        }
      }
    }
  }
`
