/**
 * This is the description of the interface
 *
 * @interface UseKeydownNavigationParams
 * @member {Generic Type} T - used to automatically infer the type of the `HTMLElement` within the array provided
 * @field {T[]} tabs - converted array from a NodeList generated by `querySelectorAll`
 */

interface UseKeydownNavigationParams<T> {
  tabs: T[]
}

export const useKeydownNavigation = <T extends HTMLElement>({
  tabs,
}: UseKeydownNavigationParams<T>) => {
  const firstItem = tabs[0]
  const lastItem = tabs[tabs.length - 1]

  const moveFocusToTab = (currentItem: T) => {
    currentItem.focus()
  }

  const moveFocusToPreviousTab = (currentItem: T) => {
    let index: number

    if (currentItem === firstItem) {
      moveFocusToTab(lastItem)
    } else {
      index = tabs.indexOf(currentItem)
      moveFocusToTab(tabs[index - 1])
    }
  }

  const moveFocusToNextTab = (currentItem: T) => {
    let index: number

    if (currentItem === lastItem) {
      moveFocusToTab(firstItem)
    } else {
      index = tabs.indexOf(currentItem)
      moveFocusToTab(tabs[index + 1])
    }
  }

  const onKeydown = (event: KeyboardEvent) => {
    const target = event.currentTarget
    let flag = false

    if (!target) return

    switch (event.key) {
      case "ArrowLeft":
        moveFocusToPreviousTab(target as T)
        flag = true
        break

      case "ArrowRight":
        moveFocusToNextTab(target as T)
        flag = true
        break

      case "Home":
        moveFocusToTab(firstItem)
        flag = true
        break

      case "End":
        moveFocusToTab(lastItem)
        flag = true
        break

      default:
        break
    }

    if (flag) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  return {
    onKeydown,
  }
}
