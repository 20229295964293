import React from "react"
import { Content } from "../content/Content"
import { graphql } from "gatsby"
import "./cta-banner.module.scss"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { Button as IButton } from "_types/Button"
import { ImageBuilder } from "_types/AssetsImage"
import { RichTextContent } from "_types/RichText"
import { ButtonParser } from "components/button/ButtonParser"

interface CTABannerProps {
  background?: ImageBuilder
  heading: string
  buttons: IButton
  description?: string
  body?: RichTextContent
  isLandingPage?: boolean
  customTrackingNumber?: string
  isAltBg?: boolean
}

export const CTABanner = ({
  background,
  heading,
  description,
  body,
  buttons,
  isLandingPage,
  customTrackingNumber,
  isAltBg,
}: CTABannerProps) => {
  let noImageClass = !background ? "CTA__buttonContainerNoImage" : ""
  const { trackingNumbers, usePhoneNumberNotTrackingNumber } =
    useGeneralSettings()
  const trackingPhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.trackingNumber : ""
  const singlePhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.phoneNumber : ""

  const contactNumber = usePhoneNumberNotTrackingNumber
    ? singlePhoneNumber
    : trackingPhoneNumber
  return (
    <div className={`CTA u-content-block-padding ${isAltBg ? "greyBg" : ""}`}>
      <div className={`fr-container`}>
        <div className="CTA__wrapper">
          {heading && <h2>{heading}</h2>}

          <div className="CTA__description">
            {description && <h3>{description}</h3>}
          </div>
          {body?.value && body?.value !== "<p><br></p>" && (
            <Content content={body} />
          )}

          {contactNumber && (
            <a
              href={`tel:${
                customTrackingNumber &&
                customTrackingNumber?.length > 0 &&
                isLandingPage
                  ? customTrackingNumber
                  : contactNumber
              }`}
              className="CTA__phone"
            >
              {customTrackingNumber &&
              customTrackingNumber?.length > 0 &&
              isLandingPage
                ? customTrackingNumber
                : contactNumber}
            </a>
          )}
          {buttons.value?.length > 0 && (
            <div className={`CTA__buttonContainer CTA__button ${noImageClass}`}>
              <ButtonParser buttons={buttons.value} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment CTABannerFragment on kontent_item_cta_banner {
    system {
      type
    }
    id
    elements {
      cta_heading {
        value
      }
      description {
        value
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      button {
        value {
          ...AnchorLinkFragment
          ...ButtonFragment
        }
      }
    }
  }
`
