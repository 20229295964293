import React from "react"
import { graphql } from "gatsby"
import { RichTextContent } from "_types/RichText"
import { Fields } from "./formBuilder-types"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { FieldsParser } from "./FieldsParser"

interface FormFieldGroupProps {
  sectionDetails?: RichTextContent
  fields: Fields
  formGridStyle?: string
}
export const FormFieldGroup = ({
  sectionDetails,
  fields,
  formGridStyle,
}: FormFieldGroupProps) => {
  const getInputFieldsCount = () => {
    let count = 0
    fields.modular_content.map(({ elements }) => {
      const fieldType = elements.field_type.value[0].codename

      if (
        fieldType === "text" ||
        fieldType === "radio" ||
        fieldType === "date" ||
        fieldType === "select"
      ) {
        count++
      }
    })
    return count
  }
  const inputFieldsCount = getInputFieldsCount()
  let columnCount
  if (formGridStyle === 'n1_column_layout') {
    columnCount = 1
  } else if (formGridStyle === 'n2_column_layout') {
    columnCount = 2
  } else if (formGridStyle === 'n3_column_layout') {
    columnCount = 3
  } else if (inputFieldsCount <= 3) {
    columnCount = inputFieldsCount
  } else {
    columnCount = 3
  }

  return (
    <div className="field-group">
      {sectionDetails && sectionDetails.value.toString() !== "<p><br></p>" && (
        <div className="field-group__section-details"><RichTextElement value={sectionDetails.value} /></div>
      )}
      <div
        className="field-group__fields"
        style={{
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
        }}
      >
        {fields.modular_content.map(({ id, elements }) => {
          const fieldType = elements?.field_type?.value
            ? elements.field_type.value[0]?.codename!
            : ""
          return (
            <FieldsParser key={id} fieldType={fieldType} elements={elements} />
          )
        })}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment FormFieldGroupFragment on kontent_item_form_field_group {
    id
    __typename
    elements {
      section_details {
        value
      }
      fields {
        modular_content {
          ...FormFieldFragment
        }
      }
    }
  }
`
