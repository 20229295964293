import React from "react"
import { graphql, Link } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import "../content-blocks/content-block.module.scss"
import { RichTextContent } from "_types/RichText"
import { ResolvedUrlLink, DomNode } from "./content-types"
import "./content.module.scss"
import { useLayout } from "contexts/components/LayoutContext"
import { Image } from "components/image/Image"
import { ImageItem } from "@kentico/gatsby-kontent-components/dist/image-element"

type ContentProps = {
  content: RichTextContent
  styleListsAsGrid?: boolean
}

export const Content = ({ content, styleListsAsGrid }: ContentProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()

  let columnCount
  if (content.value.includes("<li>")) {
    const filteredList = content.value.split("<li>")
    columnCount = filteredList.length - 1 > 4 ? "three-column-grid" : "two-column-grid"
  }

  const listsAsGrid = styleListsAsGrid ? ` fr-content-grid-list ${columnCount}` : ""

  const contentFormatted = content.value.includes("iframe")
    ? content.value.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
    : content.value

  const resolveLink = (link: ResolvedUrlLink, domNode: DomNode) => {
    if (link) {
      const slug = linkedPagesUrlPathCodex[link.url_slug]
      const finalSlug = !!slug ? `/${slug}` : "/404"
      if (domNode.children[0] && domNode.children[0].children) {
        return (
          <Link to={finalSlug}>
            {domNode.children[0].children[0].data as string}
          </Link>
        )
      } else if (domNode.children[0] && domNode.children[0].data) {
        return <Link to={finalSlug}>{domNode.children[0].data as string}</Link>
      }
    }
    return <></>
  }

  const resolveImage = (image?: ImageItem) => {
    if (!image) return <></>
    return (
      <Image
        image={image}
        alt={image.description}
        objectFit="cover"
        objectPosition="center"
        className="fr-content-container__image"
      />
    )
  }

  return (
    <div className={`fr-content-container ${listsAsGrid}`}>
      <RichTextElement
        value={contentFormatted}
        links={content.links}
        resolveLink={resolveLink}
        images={content.images}
        resolveImage={resolveImage}
      />
    </div>
  )
}

export const query = graphql`
  fragment RichTextContentFragment on kontent_item_rich_text_element_value {
    images {
      description
      height
      image_id
      url
      width
    }
    links {
      link_id
      url_slug
    }
    value
  }
`
