import React, { HTMLAttributes } from "react"
import { graphql, Link } from "gatsby"
import "./image-link.module.scss"
import { ExternalLink } from "components/external-link/ExternalLink"
import { Image } from "components/image/Image"
import { KenticoImageLink } from "./image-link"

type AnchorWithoutClassName = Omit<
  HTMLAttributes<HTMLAnchorElement>,
  "className"
>

interface ImageLinkProps extends AnchorWithoutClassName {
  imageLink: KenticoImageLink["elements"]
}

export const ImageLink = ({ imageLink, ...rest }: ImageLinkProps) => {
  const image = imageLink.image.value[0]
  const url = imageLink?.url?.value
  const internal_link = imageLink.internal_link?.value[0]?.elements?.slug.value

  if (internal_link) {
    return (
      <Link
        aria-label={image.description || "Affiliate And Accreditation Logo"}
        to={`${internal_link}`}
        {...rest}
      >
        <Image
          image={image}
          width={image.width}
          alt={image.description}
          className="image-link"
        />
      </Link>
    )
  } else {
    return (
      <ExternalLink
        aria-label={image.description || "Affiliate And Accreditation Logo"}
        href={url}
        {...rest}
      >
        <Image
          image={image}
          width={image.width}
          alt={image.description}
          className="image-link"
        />
      </ExternalLink>
    )
  }
}

export const query = graphql`
  fragment ImageLinkFragment on kontent_item_image_link {
    system {
      type
    }
    id
    elements {
      image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      internal_link {
        value {
          ... on kontent_item_page {
            id
            elements {
              slug {
                value
              }
            }
          }
        }
      }
      url {
        value
      }
    }
  }
`
