import React, { HTMLAttributes } from "react"
import "./repeating-image.module.scss"
import { CmsAssetImage } from "_types/AssetsImage"
import { Image } from "components/image/Image"

type DivWithoutClassName = Omit<HTMLAttributes<HTMLDivElement>, "className">

interface RepeatingImageProps extends DivWithoutClassName {
  images: {
    single_image: CmsAssetImage
  }
}

export const RepeatingImage = ({ images }: RepeatingImageProps) => {
  const image = images.single_image.value[0]
  return (
    <div className="image-wrapper">
      <Image
        image={image}
        alt={image.description}
        className="image"
        height={image.height}
        width={image.width}
      />
    </div>
  )
}
