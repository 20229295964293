import React from "react"
import { Button } from "../../button"
import { Image } from "../../image/Image"
import { ImageBuilder } from "../../../_types/AssetsImage"
import { BlogCardContainer } from "./BlogCardContainer"
import "./blog-card.module.scss"
import { useLayout } from "contexts/components/LayoutContext"

interface BlogCardProps {
  image?: ImageBuilder
  heading?: string
  description?: string
  slug: string
  hasButton?: boolean
  hasDateTag?: boolean
  hasDateTitle?: boolean
  date?: string
  customOverlayCTAText?: string
}

export const BlogCard = ({
  heading,
  description,
  image,
  slug,
  date,
  hasButton,
  customOverlayCTAText,
}: BlogCardProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()
  const pageSlug = linkedPagesUrlPathCodex[slug] || "404"
  const path = pageSlug.startsWith("/") ? pageSlug : `/${pageSlug}`

  return (
    <BlogCardContainer hasButton={hasButton} slug={path}>
      <div
        className={`fr-blog-card__image-container ${
          !hasButton
            ? "fr-blog-card__image-container--with-linear-gradient"
            : ""
        }`}
      >
        { image && (
          <Image
		  		alt={ image.description }
				className="fr-blog-card__featured-image"
     	    	image={ image }
            	objectFit="cover"
          />
        ) }

        <div className="fr-blog-card__overlay">
            { heading && (
            	<h3 className="fr-blog-card__heading">{ heading }</h3>
            ) }

			{ date && (
				<time className="fr-blog-card__published-date-text" dateTime={ date }>
					{ date }
				</time>
			) }

			<span className="fr-blog-card__cta-tag">
				{ customOverlayCTAText ? customOverlayCTAText : "Read more" }
            </span>
        </div>
      </div>
      { hasButton && (
        <Button
          style={{ marginTop: "auto" }}
          to={ path }
          variant="solid-secondary"
        >
          Read More
        </Button>
      ) }
    </BlogCardContainer>
  )
}
