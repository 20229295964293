import React from 'react'
import { useGeneralSettings } from "hooks/useGeneralSettings"

export const FormErrorMessage = () => {
  const { trackingNumbers } = useGeneralSettings()
  const mainOfficePhoneNumber = trackingNumbers[0].phoneNumber
  const phone = mainOfficePhoneNumber

  return (
    <div
      // className={formStyles.formError}
      id="form-error"
    >
      <h2>Form Error</h2>
      <p>
        Our apologies, our system has encountered an error. Please call{" "}
        <a
          href={`tel:${phone}`}
          // className={formStyles.phone}
        >
          {phone}
        </a>{" "}
        to complete your submission.
      </p>
    </div>
  )
}
